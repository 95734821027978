<template>
  <auth-layout :background-image="imgUrl">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      {{ $t("auth.register.create-account") }}
    </b-card-title>

    <!-- form -->
    <validation-observer ref="registerValidation">
      <b-form class="auth-register-form mt-2" @submit.prevent>
        <!-- username -->
        <b-form-group :label="$t('fields.full-name')" label-for="register-username">
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-input
              id="register-username"
              v-model="form.name"
              name="name"
              type="text"
              :state="errors.length > 0 ? false : null"
              placeholder="Your Full Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- email -->
        <b-form-group :label="$t('fields.email')" label-for="register-email">
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="required|email"
          >
            <b-form-input
              id="register-email"
              v-model="form.email"
              name="email"
              type="email"
              autocomplete="email"
              :state="errors.length > 0 ? false : null"
              placeholder="Your Email Address"
            />
            <small class="font-small-2 text-secondary">
              <feather-icon icon="InfoIcon"/>
              Email given by your company
            </small>
            <br>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- password -->
        <b-form-group
          label-for="register-password"
          :label="$t('fields.password')"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="register-password"
                v-model="form.password"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                name="password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="terms_of_use"
            :rules="{ required: { allowFalse: false } }"
          >
            <b-form-checkbox
              id="register-terms-of-use"
              v-model="form.terms_of_use"
              name="terms_of_use"
            >
              <b-link href="https://facerecognition.io/terms/" target="_blank">
                {{ $t("auth.register.terms-of-use") }}
              </b-link>
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="privacy_policy"
            :rules="{ required: { allowFalse: false } }"
          >
            <b-form-checkbox
              id="register-privacy-policy"
              v-model="form.privacy_policy"
              name="privacy-policy"
            >
              <b-link
                href="https://facerecognition.io/privacy/"
                target="_blank"
              >
                {{ $t("auth.register.privacy-policy") }}
              </b-link>
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          variant="primary"
          block
          type="submit"
          :disabled="loading"
          @click="validateForm"
        >
          <template v-if="loading">
            <b-spinner small />
            <span class="sr-only"> {{ $t("general.loading") }}</span>
          </template>
          <span v-if="!loading">{{ $t("auth.register.action") }}</span>
        </b-button>
      </b-form>
    </validation-observer>

    <p class="text-center mt-2">
      <span>{{ $t("auth.register.redirect-sign-in-message") }}</span>
      <router-link :to="{ name: 'login' }">
        <span>&nbsp;{{ $t("auth.register.redirect-sign-in-link") }}</span>
      </router-link>
    </p>

    <recaptcha-policy />
    <b-modal
        id="personel-use-info-modal"
        centered
        title="Select Your Use Case"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc>
      <b-button
          v-ripple.400="'rgba(208, 210, 214, 0.15)'"
          block
          variant="outline-secondary"
          v-b-toggle="'collapse-1'"
          style="color: #d0d2d6; border: 1px solid #d0d2d6 !important;">
        <span> Personal Use </span>
      </b-button>
      <b-collapse id="collapse-1" class="mt-1">
        <b-alert variant="mb-0" fade show>
          <div class="alert-body d-flex flex-column">
            <span class="mb-1">You can use our other application for personal use.</span>
            <a :href="googlePlay.url">
              <img
                  :src="googlePlay.appImg"
                  class="mr-50"
                  alt="facematch-live"
                  style="max-height: 45px;border-radius: 0.358rem;"/>
              <img :src="googlePlay.img" alt="google-play"/>
            </a>
          </div>
        </b-alert>
      </b-collapse>
      <b-button
          v-ripple.400="'rgba(208, 210, 214, 0.15)'"
          block
          variant="outline-secondary mt-1"
          style="color: #d0d2d6; border: 1px solid #d0d2d6 !important;"
          @click="$bvModal.hide('personel-use-info-modal')">
        <span> Organizational Use </span>
      </b-button>
    </b-modal>
  </auth-layout>
</template>

<script>
import AuthLayout from "./components/AuthLayout.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";

import RecaptchaPolicy from "@/components/ReCaptchaPolicy.vue";

export default {
  components: {
    AuthLayout,
    ValidationProvider,
    ValidationObserver,
    RecaptchaPolicy,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {
    this.$bvModal.show('personel-use-info-modal');
  },
  data() {
    return {
      loading: false,
      sideImg: require("@/assets/images/pages/reset-password-v2.svg"),
      form: {
        recaptcha: "",
        name: "",
        email: "",
        password: "",
        privacy_policy: false,
        terms_of_use: false,
      },
      required,
      email,
      googlePlay: {
        img: require("@/assets/images/pages/google-play.png"),
        appImg: require("@/assets/images/pages/facematch-live.png"),
        url: 'https://play.google.com/store/apps/details?id=com.appfab.facematchlive'
      }
    };
  },
  methods: {
    async verify() {
      if (process.env.NODE_ENV === "production") {
        await this.$recaptchaLoaded();
        this.form.recaptcha = await this.$recaptcha("register");
      }
    },
    validateForm() {
      this.loading = true;

      this.$refs.registerValidation.validate().then(async (success) => {
        if (!success) return;

        await this.verify();

        await this.$auth
            .register({
              data: this.form,
              redirect: {name: "verify-email"},
              staySignedIn: true,
              fetchUser: true,
            })
            .then(
                (data) => {
                },
                ({
                   response: {
                     data: {title, invalid_params},
                   },
                 }) => {
                  this.$refs.registerValidation.setErrors(invalid_params);
                }
            );
      }).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style>
#personel-use-info-modal .list-group-item {
  border: unset;
  border-radius: 0.358rem;
}
#personel-use-info-modal___BV_modal_backdrop_ {
  opacity: 1;
}
</style>
